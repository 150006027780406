
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Top from "@/views/Frame/Top.vue";
import LeftNav from "@/views/Frame/LeftNav.vue";
import { Message } from 'element-ui';
interface Status {
  status: boolean;
}
@Component({
  name: "Frame",
  props: {},
  components: { Top, LeftNav },
  beforeRouteUpdate (to, from, next) {
   next()
  }
})
export default class IncumbentManage extends Vue {
  //init data

  @Provide("isCollapse")
  isCollapse: Status = {
    status: false,
  };
  zzlECharts: any;
  //computed
  //get example() {}
  //sds
  //methods
  //lifecycle
  mounted() {
    let isLogin = window.sessionStorage.getItem('loginStatus') === '1'
    if(!isLogin){
      this.$router.push({name:'Login'}).catch(()=>{})
    }else{
      window.onresize  = ()=>{
        if (window.innerWidth < 1300) {
          this.isCollapse.status = true
        } else {
          this.isCollapse.status = false
        }
      }
    }
  }
}
