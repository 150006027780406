
import routes from '@/router/router'
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
interface NavItem {
  iconName: string;
  title: string;
  routerName: string;
  isLeaf: boolean;
  open?: boolean;
  children?: SubNavItem[] | undefined;
}
interface SubNavItem {
  title: string;
  routerName: string;
}
@Component({
  name: "LeftNav",
  props: {},
  components: {},
})
export default class LeftNav extends Vue {
  //init data
  open: boolean = false;
  activeIndex: number = 0;
  currentTitle: string | null = "";
  title: string = "";
  navList: Array<NavItem> = [];
  get navLists(){
    return routes[0].children
  }
  get isLeaf(){
    return (ite:any)=>{
      return !ite.children || ite.children!.length===0;
    }
  }
  //computed
  //get example() {}
  @Inject()
  isCollapse: any;

  currentRoleName: any = "";
  zzlApi: any;
  //methods

  /* 展开列表 */
  openMenu(item: any) {
    if (this.isCollapse.status) return;
    this.navList.map((x, i) => {
      if (item.title === x.title) {
        x.open = !x.open;
      } else {
        x.open = false;
      }
    });
    this.currentTitle = "";
  }
  isActive(item: any) {
    console.log(item);
    return true;
  }
  /* 切换菜单 */
  navItemClick(item: any, isTopNav: boolean) {
    if (isTopNav) {
      this.navList.map((x, i) => {
        x.open = false;
      });
    }
    this.currentTitle = item.title;
    window.sessionStorage.setItem("currentNavTitle", item.title);
    this.$router.push({ name: item.name }).catch(() => {});
  }
  /* 获得侧边栏数据 */
  async getNavList() {
    const res = await this.zzlApi.home.Index({});
    if (res.code === 0) {
      this.title = res.data.title;
      const dictionary: any = JSON.stringify(res.data.dictionaries);
      window.sessionStorage.setItem("dictionary", dictionary);
      this.setDictionaries(res.data.dictionaries);
    } else {
      this.$message.error(res.msg);
    }
  }
   setDictionaries(dictionaries:any) {
     this.$store.commit("setDictionaries", dictionaries);
   }
  getAuthList(arr: any) {
    arr.map((item: any, index: any) => {
      this.$store.state.auth_list.push(item.action);
      if (item.children.length > 0) {
        this.getAuthList(item.children);
      }
    });
  }
  changeStatus() {
    this.isCollapse.status = !this.isCollapse.status;
  }
  @Watch("isCollapse.status")
  isCollapseChange(newVal: any, oldVal: any) {
    this.navList.map((item) => {
      if (item.open) {
        item.open = false;
      }
    });
  }
  //lifecycle
  mounted() {
    console.log(routes, "111");
    const status = window.sessionStorage.getItem("loginStatus");
    if (status === "1") {
      this.getNavList(); //待导航配置完成后 再对接
      this.currentTitle = window.sessionStorage.getItem("currentNavTitle");
      if(!this.currentTitle && routes.length){
        (routes[0] as any).children.some((s:any)=>{
             this.currentTitle=s.title;
             return !s.noShow
        })
      }

      this.currentRoleName = window.sessionStorage.getItem("currentRoleName");
    }
  }
  
}
