
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import NewModal from "@/components/NewModal.vue";


@Component({
  name: "Top",
  props: {},
  components: {NewModal  },
})
export default class IncumbentManage extends Vue {
  zzlHttp: any;
  //init data
  zzlFormat: any;

  userName: any = "";
  passwordDialog: any = {
    visible: false,
    title: "修改密码",
    width: "35%",
    showFooter: true,
  };
  form: any = {
    oldPassWord: "",
    newPassWord: "",
    rePassWord: "",
  };
  dialog: any = {
    visible: false,
    title: "联系我们",
    width: "40%",
  };
  noticeDialog: any = {
    visible: false,
    title: "系统公告",
    top: "20vh",
    width: "40%",
  };
  zzlApi: any;
  initCode: number = 0;
  //computed
  //get example() {}
  @Inject()
  isCollapse: any;
  //methods

  openPasswordDialog() {
    this.passwordDialog.visible = true;
  }
  closePasswordDialog() {
    this.passwordDialog.visible = false;
    this.form = {
      oldPassWord: "",
      newPassWord: "",
      rePassWord: "",
    };
    (this.$refs["password"] as HTMLFormElement).resetFields();
  }

  openNoticeDialog() {
    this.noticeDialog.visible = true;
  }
  closeNoticeDialog() {
    this.noticeDialog.visible = false;
  }

  openDialog() {
    
    this.dialog.visible = true;
  }
  closeDialog() {
    this.dialog.visible = false;
  }
  async signOut() {
    sessionStorage.clear();
    this.$router.push({ name: "Login" }).catch(() => {});
    //只需要调用  不需要wait其返回
    const res = await this.zzlApi.home.Logout();
  }
  //lifecycle
  mounted() {
    this.userName = window.sessionStorage.getItem("userName");
 
  }
}
