import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=5dbb3d98&scoped=true"
import script from "./Top.vue?vue&type=script&lang=ts"
export * from "./Top.vue?vue&type=script&lang=ts"
import style0 from "./Top.vue?vue&type=style&index=0&id=5dbb3d98&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbb3d98",
  null
  
)

export default component.exports